import "core-js/modules/web.timers.js";
import "core-js/modules/es.parse-int.js";
import * as _ from 'lodash';
import FormValidator from '../libs/misc/form-validator';
import AVLive from '../libs/misc/mil-av-live';
(function (init) {
  init(window.jQuery, window, document);
})(function ($, window, document) {
  // shim layer with setTimeout fallback
  window.requestAnimationFrame = function () {
    return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || function (callback) {
      window.setTimeout(callback, 1000 / 60);
    };
  }();
  function init_common() {
    $('body').removeClass('preloading');
    if ($('#nav-toggler').length == 0) return;
    $('#nav-toggler').click(function () {
      $(this).toggleClass('open');
      $('#nav-ul-wrapper').toggleClass('open');
    });
    window.onresize = _.debounce(closeNav, 20);
  }
  var closeNav = function closeNav() {
    $('#nav-ul-wrapper').removeClass('open');
    $('#nav-toggler').removeClass('open');
  };
  function init_register_events() {
    var registerFormValidator = new FormValidator({
      form: {
        id: '#register-form-event',
        errors: '.register-errors',
        btn_submit: '#btn-register',
        recaptcha: '#g-recaptcha-response'
      },
      fields: ['name', 'surname', 'email',
      // "email_check",
      'remember'],
      init_fields: false,
      errors: {
        name: 'Inserisci un nome valido',
        surname: 'Inserisci un cognome valido',
        email: 'Inserisci una email valida',
        //email_check: 'Email e conferma e-mail sono diversi',
        remember: 'Accetta il regolamento e la privacy policy'
      },
      password_mode: false,
      remote_errors: []
    });
    var is_register = registerFormValidator.initialize();
  }
  function init_register_workshops() {
    if ($('#register-form-workshop').length > 0) {
      var $yearpicker = $('.yearpicker');
      $yearpicker.yearpicker({
        // Initial Year
        year: $yearpicker.val() ? parseInt($yearpicker.val()) : null,
        // Start Year
        startYear: 1900,
        // End Year
        endYear: 2022,
        // Element tag
        itemTag: 'li',
        // Default CSS classes
        selectedClass: 'selected',
        disabledClass: 'disabled',
        hideClass: 'hide',
        // Custom template
        template: "<div class=\"yearpicker-container\">\n                              <div class=\"yearpicker-header\">\n                                  <div class=\"yearpicker-prev\" data-view=\"yearpicker-prev\">&lsaquo;</div>\n                                  <div class=\"yearpicker-current\" data-view=\"yearpicker-current\">SelectedYear</div>\n                                  <div class=\"yearpicker-next\" data-view=\"yearpicker-next\">&rsaquo;</div>\n                              </div>\n                              <div class=\"yearpicker-body\">\n                                  <ul class=\"yearpicker-year\" data-view=\"years\">\n                                  </ul>\n                              </div>\n                           </div>"
      });
    }
    var registerFormValidator = new FormValidator({
      form: {
        id: '#register-form-workshop',
        errors: '.register-errors',
        btn_submit: '#btn-register',
        recaptcha: '#g-recaptcha-response'
      },
      fields: ['name', 'surname', 'email',
      //"email_check",
      'birthday', 'remember'],
      init_fields: false,
      errors: {
        name: 'Inserisci un nome valido',
        surname: 'Inserisci un cognome valido',
        email: 'Inserisci una email valida',
        //email_check: 'Email e conferma e-mail sono diversi',
        remember: 'Accetta il regolamento e la privacy policy'
      },
      password_mode: false,
      remote_errors: []
    });
    var is_register = registerFormValidator.initialize();
  }
  function init_register_ovelap() {
    var registerFormValidator = new FormValidator({
      form: {
        id: '#register-form-overlap',
        errors: '.register-errors',
        btn_submit: '#btn-overlap-submit',
        recaptcha: '#g-recaptcha-response'
      },
      fields: [],
      init_fields: false,
      errors: {},
      password_mode: false,
      remote_errors: []
    });
    var is_register = registerFormValidator.initialize();
  }
  $(function () {
    init_common();
    init_register_events();
    init_register_workshops();
    init_register_ovelap();
  });
});